/* Tier coloring in leaderboards and standings*/
td.D1 {
  background-color: rgba(212, 175, 55, 0.5);
}
td.D2 {
  background-color: rgba(175, 175, 175, 0.5);
}
td.D3 {
  background-color: rgba(215, 153, 91, 0.5);
}
td.D4, td.DInactive {
  background-color: rgba(255, 255, 242, 1.0);
}
td.DInactive {
  color: rgba(128, 128, 128, 0.5);
  font-style: italic;
}

/* Hover highlighting for leaderboards*/
tr.highlight {
  outline: 1px solid rgb(100, 100, 100);
}
tr.highlight td.D1 {
  background-color: rgba(212, 175, 55, 1.0);
}
tr.highlight td.D2 {
  background-color: rgba(175, 175, 175, 1.0);
}
tr.highlight td.D3 {
  background-color: rgba(215, 153, 91, 1.0);
}
tr.highlight td.D4 {
  background-color: rgba(255, 255, 200, 1.0);
}
.ui.definition.table tr.highlight td:first-child {
  background-color: black;
  color: white;
}

/* Hiding inactive managers in leaderboards*/
tr.hidden {
  display: none;
}
/*
table.ui.table {
  border-collapse: collapse;
}*/

/* Trophy display in leaderboards*/
span.trophies {
  display: inline-block;
  margin-right: 2px;
}

/* ADP Coloring */
tr.C {
  background-color: rgba(252, 173, 95);
}

tr.LW, tr.RW, tr.W, tr.CRW, tr.CLW, tr.F {
  background-color: rgba(45, 203, 183);
}

tr.D, tr.RWD, tr.LWD {
  background-color: rgba(37, 170, 241);
}

tr.G {
  background-color: rgba(245, 46, 105);
}
